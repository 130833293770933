import {Action, CUSTOM_PREVIEW_ID_PREFIX, NESTED_COMPONENT_PREVIEW_ID_PREFIX, Event, DuplicatePageError} from './_consts.js';


export default function Actions({ messenger, events, common }) {
  let _currentPreviewElement = null;
  let _currentPreviewLanguage = null;

  events.on(Event.ElementChange, () => messenger.sendMessage({ setPreviewElement: true, previewId: _currentPreviewElement }, false));

  events.on(Event.WorkflowTransition, async ({ previewId, isDeleted }) => {
    if (isDeleted) {
      await events.emit(Event.NavigationChange);
      events.emit(Event.ElementChange, { previewId, content: null });
    } else {
      events.emit(Event.StatusChange, { previewId, status: await getElementStatus(previewId) });
    }
  });

  async function sendAction(action, params = {}, result = true) {
    if(!params.previewLanguage && _currentPreviewLanguage) {
      params.previewLanguage =  _currentPreviewLanguage;
    }
    return messenger.sendAction(action, params, result);
  }

  const _statusCache = (() => {
    const _cache = new Map();

    const get = async (previewId, refresh = false, componentPath = []) => {
      if ( (componentPath.length == 0 ? !_cache.has(previewId) : !_cache.has(previewId+"#"+componentPath.join(":"))) || refresh) {
        if (previewId.startsWith(CUSTOM_PREVIEW_ID_PREFIX)) {
          const custom = previewId.substr(CUSTOM_PREVIEW_ID_PREFIX.length);
          _cache.set(previewId, { custom, parts: custom.split(':') });
        } else {
          const status = await sendAction(Action.STATUS, { previewId });
          if(status.name && status.name !== "unknown") {
            if( componentPath.length == 0 ) {
              _cache.set(previewId, Object.assign(status, { custom: null }));
            } else {
              _cache.set(previewId+"#"+componentPath.join(":"), Object.assign(status, { componentPath, custom: null }));
            }
          }
          return status;
        }
      }
      return _cache.get(componentPath.length == 0 ? previewId : previewId+"#"+componentPath.join(":"));
    };

    const invalidate = (previewId = null) => {
      if (previewId === null) {
        _cache.clear();
      } else {
        _cache.delete(previewId);
      }
    };

    return { get, invalidate };
  })();

  const _statusChanged = async (previewId) => {
    _statusCache.invalidate(previewId);
    const status = await getElementStatus(previewId);
    events.emit(Event.StatusChange, { previewId, status });
  };

  const _projectInfo = (() => {
    let _cache = null;
    const _project = async(invalidate = false) => {
      if (invalidate || _cache === null) {
        _cache = await sendAction(Action.PROJECT_INFO);
      }
      return _cache;
    };

    const languages = async(invalidate) => {
      const project = await _project(invalidate);
      let languages = [];
      for (let { lang, master } of project.languages) {
        if (master) {
          _currentPreviewLanguage = _currentPreviewLanguage || lang;
          languages.unshift(lang);
        } else {
          languages.push(lang);
        }
      }
      return languages;
    };

    const previewUrl = async(invalidate) => {
      const { previewUrl } = await _project(invalidate);
      return previewUrl;
    };

    return { languages, previewUrl };
  })();


  async function execute(identifier, params = {}, result = true) {
    if (typeof identifier === "function") {
      return messenger.sendMessage({execute: {source: identifier.toString()}, params}, {result});
    } else {
      return messenger.sendMessage({execute: identifier, params}, {result});
    }
  }

  function getPreviewElement() {
    return _currentPreviewElement;
  }

  async function setPreviewElement(previewId) {
    _currentPreviewElement = previewId;
    if (previewId !== null) {
      const { language } = await getElementStatus(previewId);
      _currentPreviewLanguage = language || _currentPreviewLanguage;
      // Clearing status cache because setting the preview element can include switching to another preview language.
      // This is essentially a work around for the fact that we don't cache status data for uuids language-dependent yet.
      _statusCache.invalidate();
    }
    messenger.sendMessage({ setPreviewElement: true, previewId }, false);
  }

  async function getPreviewLanguage() {
    return _currentPreviewLanguage;
  }

  async function requestChangeSet(previewIds) {
    return sendAction(Action.REQUEST_CHANGE_SET, { previewIds });
  }

  async function showComparisonDialog(previewId) {
    const action = sendAction(Action.SHOW_COMPARISON_DIALOG, { previewId });
    mayTriggerChange(previewId, action);
  }

  async function showEditDialog(previewId, { nestedComponentPath = null } = {}) {
    let action;
    if(nestedComponentPath) {
        action = sendAction(Action.EDIT_NESTED_COMPONENT, { previewId, nestedComponentPath });
    } else {
        action = sendAction(Action.EDIT, { previewId });
    }
    mayTriggerChange(previewId, action);
  }

  async function showMetaDataDialog(previewId) {
    const action = sendAction(Action.EDIT_META_DATA, { previewId });
    mayTriggerChange(previewId, action);
  }

  async function getElementStatus(previewId, refresh = false, componentPath = []) {
    if (!previewId) throw new Error(`Missing PreviewId!`);
    return _statusCache.get(previewId, refresh, componentPath);
  }

  async function renderElement(previewId = null) {
    if (previewId === null) {
      return await sendAction(Action.RENDER_START_NODE);
    } else {
      return await sendAction(Action.RENDER, { previewId });
    }
  }

  async function deleteElement(previewId, showConfirmDialog = false) {
    const status = await getElementStatus(previewId);
    const isInNavigation = ['Page', 'PageRef', 'PageRefFolder'].includes(status.elementType) || status.elementType.startsWith('Dataset');
    const result = await sendAction(Action.DELETE, { previewId, showConfirmDialog });
    _statusCache.invalidate();
    if (result) {
      if (isInNavigation) await events.emit(Event.NavigationChange);
      events.emit(Event.ElementChange, { previewId, content: null });
    } else {
      messenger.sendSubject('error', `Unable to delete ${status.elementType} "${status.displayName}" (id:${status.id})!`);
    }
  }

  async function startWorkflow(previewId, workflow) {
    _statusCache.invalidate(previewId);
    return await sendAction(Action.WORKFLOW_START, { previewId, workflowUID: workflow });
  }

  async function processWorkflow(previewId, transition) {
    _statusCache.invalidate(previewId);
    return await sendAction(Action.WORKFLOW_PROCESS, { previewId, transitionId: transition });
  }

  async function createPage(path, name, template, { language = _currentPreviewLanguage, result = false, showFormDialog = true, forceUid = false } = {}) {
    const content = await sendAction(Action.CREATE_PAGE, { path, isUidPath: true, uid: name, template, language, showFormDialog, forceUid });
    if (forceUid && content && content.isException) {
    	throw new DuplicatePageError(`Failed to create page due to duplicate element with uid '${name}'.`, content.pagePreviewId);
    }
    _statusCache.invalidate();
    await events.emit(Event.NavigationChange);
    if (result) {
      return content;
    } else {
      events.emit(Event.RerenderView);
    }
  }

  async function createSection(previewId, { body = null, template = null, name = null, index = null, result = false } = {}) {
    const status = await getElementStatus(previewId);
    let pos = (index !== null) ? { positionIndex: ''+index, position: 'INDEX' } : { positionIndex: null, position: 'LAST' };
    _statusCache.invalidate();

    if (status.elementType === 'Section') {
      pos.position = pos.position === 'LAST' ? 'AFTER' : pos.position;
      const content = await sendAction(Action.CREATE_SIBLING_SECTION, { previewId, template, sectionName: name, position: pos.position, positionIndex: pos.positionIndex });
      if (result) {
        return content;
      } else {
        events.emit(Event.RerenderView);
      }

    } else if (['PageRef', 'Page', 'Body'].includes(status.elementType)) {
      const action = await sendAction(Action.CREATE_CHILD_SECTION, { previewId, body, template, sectionName: name, position: pos.position, positionIndex: pos.positionIndex });
      if (result) {
        return await action;
      } else {
        mayTriggerChange(previewId, action);
      }
    }
  }

  async function createDataset(template, { language = _currentPreviewLanguage, result = false } = {}) {
    const content = await sendAction(Action.CREATE_DATASET, { template, language });
    _statusCache.invalidate();
    if (result) {
      return content;
    } else {
      events.emit(Event.RerenderView);
    }
  }

  async function cropImage(previewId, resolution = 'ORIGINAL', result = false) {
    const content = await sendAction(Action.CROP_IMAGE, { previewId, resolution });
    if (content !== null) {
      if (result) {
        return content;
      } else {
        triggerChange(previewId, content);
      }
    }
  }

  // Do not use directly
  async function _transferSection(sectionId, targetId, { position = 'AFTER', mode = 'MOVE', skipRerenderEvent = false } = {}) {
    let success = await sendAction(Action.TRANSFER_SECTION, { sectionId, targetId, position, mode });
    if (success && !skipRerenderEvent) {
      events.emit(Event.RerenderView);
    }
    return success;
  }

  async function toggleBookmark(previewId) {
    await sendAction(Action.TOGGLE_BOOKMARK, { previewId });
    await _statusChanged(previewId);
  }

  async function languages(force = false) {
    return _projectInfo.languages(force);
  }

  async function previewUrl(force = false) {
    return _projectInfo.previewUrl(force);
  }

  async function showTranslationDialog(previewId, source, target) {
    const action = sendAction(Action.TRANSLATION, { previewId, source, target });
    mayTriggerChange(previewId, action);
  }

  async function showMessage(message, kind="info", title=null) {
    if (!(['info', 'error'].includes(kind))) kind = "info";
    return await messenger.sendAction(Action.SHOW_CUSTOM_DIALOG, { message, kind, title });
  }

  async function showQuestion(message, title=null) {
    return await messenger.sendAction(Action.SHOW_CUSTOM_DIALOG, { message, kind: "question", title });
  }

  const triggerChange = async (previewId, content = null) => {
    _statusChanged(previewId);
    if (content === null) {
      try {
        content = await renderElement(previewId);
      } catch(ignore) {}
    }
    events.emit(Event.ElementChange, { previewId, content });
  };
  const mayTriggerChange = async (previewId, action) => {
    const content = await action;
    if (content === null) {
      _statusChanged(previewId);
    } else {
      triggerChange(previewId, content);
    }
  };

  const triggerRerenderView = () => events.emit(Event.RerenderView);

  const _mppWrapper = async (method, ...args) => messenger.sendSubject('mpp', { method, args });
  const mppGetParameter = (name) => _mppWrapper('getParameter', name);
  const mppGetTimeParameter = () => _mppWrapper('getTimeParameter');
  const mppIsParameterized = () => _mppWrapper('isParameterized');
  const mppSetParameter = (name, value) => _mppWrapper('setParameter', name, value);
  const mppSetTimeParameter = (date) => _mppWrapper('setTimeParameter', date);

  return {
    execute,
    getPreviewElement,
    setPreviewElement,
    getPreviewLanguage,
    showEditDialog,
    showMetaDataDialog,
    getElementStatus,
    renderElement,
    deleteElement,
    startWorkflow,
    processWorkflow,
    createPage,
    createSection,
    createDataset,
    _transferSection,
    cropImage,
    toggleBookmark,
    triggerChange,
    triggerRerenderView,
    languages,
    previewUrl,
    showTranslationDialog,
    showMessage,
    showQuestion,
    mppGetParameter,
    mppGetTimeParameter,
    mppIsParameterized,
    mppSetParameter,
    mppSetTimeParameter,
    requestChangeSet,
    showComparisonDialog
  };
};
